import React from 'react';
import { SettingsElement } from './settingsGbTypes';
import SingleSettingRenderer from './SingleSettingRenderer';
import { Card, Col, Row } from 'antd';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';

type SettingsGBProps = {
  elements: SettingsElement[];
  onChange: (key: string, value: any) => void;
  values: { [key: string]: any };
  settingTitle?: string;
};

const SettingsGB = ({ elements, onChange, values, settingTitle }: SettingsGBProps) => {
  return (
    <div className="builder__settings-sidebar__container">
      <Col span={24} className="builder__settings-sidebar__container">
        <Card
          className="settings-card"
          title={settingTitle}
          bordered={false}
          bodyStyle={{ paddingTop: settingTitle ? '10px' : 'unset' }}
        >
          <Row className="builder__settings-sidebar__row" gutter={[16, 16]}>
            {elements.map((el, index) => {
              const disabled =
                (values?.isPresetOn || (el.enabilityKey && !values[el.enabilityKey])) &&
                !el?.shouldNotDisableWhenPresetIsOn;
              
              const enablerElement = el.enabilityKey ? 
                elements.find(item => item.key === el.enabilityKey) : null;
              const hasPreviewImage = enablerElement?.otherProps?.previewImageWhenDisabled;
              
              if (el.enabilityKey && !values[el.enabilityKey] && hasPreviewImage) {
                return null;
              }
              
              return (
                <React.Fragment key={index}>
                  <Col span={el.block ? 12 : 24} className={el.classes}>
                    <BuilderSettingsTitle
                      disabled={disabled}
                      title={el.title || ''}
                      {...(el?.icon && { icon: el?.icon })}
                    />
                    <SingleSettingRenderer element={el} onChange={onChange} values={values} />
                  </Col>
                </React.Fragment>
              );
            })}
          </Row>
        </Card>
      </Col>
    </div>
  );
};

export default SettingsGB;
