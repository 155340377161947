import React, { useState } from 'react';
import { useSubscriptionInfo } from '../../../UI/redux/userSlice';
import { useEmptyGeneralSettings } from '../../redux/builderSlice';
import { headings, messages } from '../elements/RichTextComponent/helpers/aiTextSettingHelpers';
import { Button } from 'antd';
import GeneralSettingsModal from '../../AdJobBuilder/components/sharedUI/GeneralSettingsModal';
import { GeneralSettingsTabView } from '../../interfaces/builderSliceTypes';
import DefaultEmptyViewForSettingsPanel from './DefaultEmptyViewForSettingsPanel';
import TokenRebuyModal from './TokenRebuyModal';

interface BlockSettingsOverlayProps {
  conditions?: { condition: boolean; messageKey: string; icon?: string; heading?: string }[];
  conditionButton?: { [key: string]: any };
}

export enum SETTINGS_OVERLAY_MESSAGE_KEY {
  IS_IN_TRIAL = 'isInTrial',
  IS_SOME_VALUE_MISSING = 'isSomeValueMissing',
  IS_AD_GENERATED_INPUT_MISSING = 'isAdGeneratedInputMissing',
  IS_OUT_OF_AI_TOKENS = 'isOutOfAiTokens'
}

const BlockSettingsOverlay = ({ conditions, conditionButton }: BlockSettingsOverlayProps) => {
  const { status } = useSubscriptionInfo();
  const emptyGeneralSettings = useEmptyGeneralSettings();
  const [isGeneralSettingsModalOpen, setIsGeneralSettingsModalOpen] = useState(false);
  const [isTokenRebuyModalOpen, setIsTokenRebuyModalOpen] = useState(false);

  const isInTrial = status === 'TRIALING';
  const isSomeValueMissing = !!emptyGeneralSettings.length;

  const disableConditions = [
    ...(conditions || []),
    {
      condition: isInTrial,
      messageKey: SETTINGS_OVERLAY_MESSAGE_KEY.IS_IN_TRIAL,
      heading: headings[SETTINGS_OVERLAY_MESSAGE_KEY.IS_IN_TRIAL],
      icon: `${process.env.PUBLIC_URL}/block-overlay/user-gear.png`
    },
    {
      condition: isSomeValueMissing,
      messageKey: SETTINGS_OVERLAY_MESSAGE_KEY.IS_SOME_VALUE_MISSING,
      heading: headings[SETTINGS_OVERLAY_MESSAGE_KEY.IS_SOME_VALUE_MISSING],
      icon: `${process.env.PUBLIC_URL}/block-overlay/ai-chip.png`
    }
  ];

  const activeCondition = disableConditions.find(({ condition }) => condition);

  const toggleGeneralSettingsModal = () => {
    setIsGeneralSettingsModalOpen(!isGeneralSettingsModalOpen);
  };

  const toggleTokenRebuyModal = () => {
    setIsTokenRebuyModalOpen(!isTokenRebuyModalOpen);
  };

  const toRenderBasedOnMessageKey: any = {
    isSomeValueMissing: {
      text: 'Zu den KI-Einstellungen',
      onClick: toggleGeneralSettingsModal
    },
    isAdGeneratedInputMissing: {
      text: 'Zu den KI-Einstellungen',
      onClick: toggleGeneralSettingsModal
    },
    isOutOfAiTokens: {
      text: 'KI-Tokens nachladen',
      onClick: toggleTokenRebuyModal
    },
    ...(conditionButton || {})
  };

  return (
    <>
      {activeCondition && (
        <div className="settings-missing-overlay">
          <DefaultEmptyViewForSettingsPanel
            title={activeCondition?.heading}
            description={messages[activeCondition.messageKey]}
            buttonLabel={toRenderBasedOnMessageKey[activeCondition.messageKey]?.text}
            onButtonClick={toRenderBasedOnMessageKey[activeCondition.messageKey]?.onClick}
            iconProps={{
              component: () => (
                <img src={activeCondition?.icon} alt="empty" style={{ width: 60, height: 60 }} />
              )
            }}
          />
        </div>
      )}

      <GeneralSettingsModal
        openJobValidationModal={isGeneralSettingsModalOpen}
        setOpenJobValidationModal={setIsGeneralSettingsModalOpen}
        settingsToOpen={emptyGeneralSettings?.[0] || GeneralSettingsTabView.COMPANY_INFORMATION}
      />
      <TokenRebuyModal
        onClose={() => {
          setIsTokenRebuyModalOpen(false);
        }}
        visible={isTokenRebuyModalOpen}
      />
    </>
  );
};

export default BlockSettingsOverlay;
