import React, { useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, Col, Popover, Progress, Row, Switch, Tooltip, message } from 'antd';
import BuilderSettingsTitle from '../../../sharedUI/BuilderSettingsTitle';
import OptionsPicker from '../../../sharedUI/OptionsPicker';
import TextArea from 'antd/lib/input/TextArea';
import BuilderSettingsTogglePro from '../../../sharedUI/BuilderSettingsTogglePro';
import {
  AI_PROMPT_TEXT_TYPES,
  GET_AI_TEXT_RESPONSE
} from '../../../../hooks/ai-conversation/getAiTextResponse';
import { cleanHtml, replaceHtmlTextColor } from '../helpers/textManipulators';
import { useEditor, useNode } from '@craftjs/core';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { setGeneralSettingsTabView, useEmptyGeneralSettings } from '../../../../redux/builderSlice';
import { useAppDispatch } from '../../../../../redux/hooks';
import { useNavigate } from 'react-router';
import { InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { updateUserSubscriptionInfo, useSubscriptionInfo } from '../../../../../UI/redux/userSlice';
import { GET_AI_TEXT_RESPONSE_FOR_NODE } from '../../../../hooks/ai-conversation/getAiTextResponseForNode';
import DefaultSmallLoader from '../../../../../SharedUI/components/DefaultSmallLoader';
import {
  AiTextSettingsProps,
  commandPlaceholderByTextType,
  textTypeDefaultLength,
  textTypeOptions,
} from '../helpers/aiTextSettingHelpers';
import ConditionalTooltip from '../../../../../SharedUI/components/ConditionalTooltip';
import { getParentNodeId, getSiblingNodeNames } from '../../../../helper/craftJs';
import { LeadsQualifiers } from '../../../../config/craftElements';
import { AiTextMessages } from '../../../../../config/messages';
import MinMaxNumberInput from '../../../../../SharedUI/components/MinMaxNumberInput';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from '../../../../../UI/utils/mixpanel';
import Title from 'antd/lib/typography/Title';
import { RetweetOutlined, EditOutlined, SelectOutlined } from '@ant-design/icons';
import { GeneralSettingsTabView } from '../../../../interfaces/builderSliceTypes';
import { getSelectedHTMLInTiptapEditorState, replaceTextInEditorState } from '../utils';
import { Editor } from '@tiptap/react';
import { CRAFT_ELEMENTS } from '../../../../AdBuilder/config/craftElements';
import {
  elementTypeObj,
  mediaTypeEnum,
  textTypesEnum
} from '../../../../AdBuilder/interfaces/TextTypeInterface';
import { isAdBuilderPath } from '../../../../AdBuilder/utils/adBuilderUtils';
import Templates from '../../../../AdBuilder/Templates/container';
import { AdGeneration } from '../../../../graphql/setAdGenerationInput';
import getAdGenerationInput from '../../../../graphql/getAdGenerationInput';
import { getChatAiUsage } from '../../../../../helper/accountOverviewHelper';
import { FaVideo } from 'react-icons/fa';
import { AD_TYPES } from '../../../../AdBuilder/Templates/container/TemplateTypesView';
import TokenRebuyModal from '../../../sharedUI/TokenRebuyModal';
import { headings } from '../helpers/aiTextSettingHelpers';
import BlockSettingsOverlay, { SETTINGS_OVERLAY_MESSAGE_KEY } from '../../../sharedUI/BlockSettingsOverlay';
import { isUserOutOfAiTokens } from '../../../../helper/aiTokensHelper';
import { aiTokenRebuyCheck } from '../../../../../Login/utils/ImageConstants';

const AiTextSettings = ({
  tiptapEditorState,
  showOptionPicker = true,
  elementType,
  changeColor,
  shouldReplaceTextColor = true,
  processAsPlainText = false,
  setCenter = false
}: AiTextSettingsProps) => {
  const { query } = useEditor();
  const getAdParentElementProps = (nodeId: string) => {
    if (isAdBuilderPath()) {
      const parentNodeId = getParentNodeId(nodeId, query, CRAFT_ELEMENTS.ELEMENT_APPENDER);
      return query.getSerializedNodes()[parentNodeId].props;
    }
  };
  const {
    actions: { setProp },
    nodeId,
    props,
    nodeName,
    parentId,
    currentNode,
    hasAdGenerationInput,
    isAiGeneratedAd,
    aiAdType
  } = useNode(node => {
    return {
      hasAdGenerationInput: getAdParentElementProps(node.id)?.hasAdGenerationInput,
      isAiGeneratedAd: getAdParentElementProps(node.id)?.isAiAd,
      aiAdType: getAdParentElementProps(node.id)?.aiAdType,
      nodeId: node.id,
      props: node.data.props,
      nodeName: node.data.name,
      parentId: node.data.parent,
      currentNode: node
    };
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const emptyGeneralSettings = useEmptyGeneralSettings();
  const subscriptionInfo = useSubscriptionInfo();
  const {
    status,
    maxChatAiTokens,
    remainingChatAiTokens,
    remainingAdditionalChatAiTokens
  } = subscriptionInfo;

  const { currentChatAiUsageProgress, currentChatAiUsagePercentage } = getChatAiUsage(
    subscriptionInfo
  );

  const [promptText, setPromptText] = useState('');
  const [shouldGenerateMore, setShouldGenerateMore] = useState(false);
  const [isUsingSelectedText, setIsUsingSelectedText] = useState(false);
  const selectedTypeLimits = textTypeDefaultLength.find(el => el.type === props.textTypeOption);
  const [textMaxLimit, setTextMaxLimit] = useState<number>(selectedTypeLimits?.max || 50);
  const [textMinLimit, setTextMinLimit] = useState<number>(selectedTypeLimits?.min || 20);
  const [previousSelection, setPreviousSelection] = useState('');
  const [allGeneratedTexts, setAllGeneratedTexts] = useState<string[]>([]);
  const [selectedTemplateText, setSelectedTemplateText] = useState('');
  const [currentConversationId, setCurrentConversationId] = useState('');
  const [textGotGenerated, setTextGotGenerated] = useState(false);
  const [freeText, setFreeText] = useState('');
  const [hasMinMaxValidationError, setHasMinMaxValidationError] = useState(false);
  const [isRebuyModalOpened, setIsRebuyModalOpened] = useState(false);
  const [aiTextElementScope, setAiTextElementScope] = useState('');

  const [currentTextTypeOption, setCurrentTextTypeOption] = useState<AI_PROMPT_TEXT_TYPES>(
    props.textTypeOption
  );
  const [adGenerationInput, setAdGenerationInput] = useState<AdGeneration>();
  const [templateModalVisible, setTemplatesModalVisible] = useState(false);

  const lastGeneratedRef = useRef<string[]>([]);

  const selectedText = tiptapEditorState
    ? tiptapEditorState.state.doc.textBetween(
        tiptapEditorState.state.selection.from,
        tiptapEditorState.state.selection.to,
        ''
      )
    : '';

  const selectedTextLength = selectedText?.length;
  const shouldUseSelectedText = !!selectedText && isUsingSelectedText;
  const { isBaseQoutaExceeded, isOutOfAiTokens } = isUserOutOfAiTokens('text', subscriptionInfo);

  const getMediaTextRangeForAdTypes = () => {
    if (aiAdType === AD_TYPES.STATEMENT) {
      return { min: 5, max: 45 };
    } else if (aiAdType === AD_TYPES.ADVANTAGES || aiAdType === AD_TYPES.EMPLOYEE_TESTIMONIAL) {
      return { min: 15, max: 55 };
    } else {
      return { min: 10, max: 50 };
    }
  };

  const elementTypeTextLimits = {
    [mediaTypeEnum.TEASER]: { min: 5, max: 35 },
    [mediaTypeEnum.CTA]: { min: 5, max: 70 },
    [textTypesEnum.HEADLINE]: { min: 1, max: 7 },
    [mediaTypeEnum.MAIN_TEXT]: { min: 10, max: 250 },
    [elementTypeObj[mediaTypeEnum.HAUPTINHALT]]: getMediaTextRangeForAdTypes()
  };

  const elementTypeTextMaxLimit = elementTypeTextLimits[elementType as string]?.max;

  const [
    getAiTextResponse,
    { loading: textGenLoading, data: generated, error: textGenError }
  ] = useLazyQuery(GET_AI_TEXT_RESPONSE, { fetchPolicy: 'no-cache' });

  const { data: existingGeneratedData, loading: existingGeneratedLoading } = useQuery(
    GET_AI_TEXT_RESPONSE_FOR_NODE,
    {
      variables: { input: { nodeId } },
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    setAdGenerationInput({ nodeId: getElementAppenderNodeId() });
  }, [getAdParentElementProps(nodeId)?.hasAdGenerationInput]);

  useEffect(() => {
    if (textGenError) {
      message.error(AiTextMessages.generateFailed);
    }
  }, [textGenError]);

  const getElementAppenderNodeId = () => {
    let parentNodeId;
    let parentNodePropsId;
    if (isAdBuilderPath()) {
      parentNodeId = getParentNodeId(nodeId, query, CRAFT_ELEMENTS.ELEMENT_APPENDER);
      parentNodePropsId = query.getSerializedNodes()[parentNodeId].props.id;
    }
    return parentNodePropsId || parentNodeId;
  };

  useEffect(() => {
    if (generated) {
      const newGeneratedTexts = generated?.getAiTextResponse?.map((text: string) => text) || [];
      lastGeneratedRef.current = newGeneratedTexts;
      setAllGeneratedTexts([...allGeneratedTexts, ...newGeneratedTexts]);
      setTextGotGenerated(true);
      const usedTokens = generated?.getAiTextResponse?.[0]?.totalTokens;
      const convId = generated?.getAiTextResponse?.[0]?.conversationId;

      const remainingTokens = isBaseQoutaExceeded
        ? Math.max(remainingAdditionalChatAiTokens - usedTokens, 0)
        : Math.max(remainingChatAiTokens - usedTokens, 0);

      const variables: any = isBaseQoutaExceeded
        ? { remainingAdditionalChatAiTokens: remainingTokens }
        : { remainingChatAiTokens: remainingTokens };

      if (usedTokens) {
        dispatch(updateUserSubscriptionInfo(variables));
      }
      if (convId) setCurrentConversationId(convId);

      setTimeout(() => setTextGotGenerated(false), 5000);
    }
  }, [generated]);

  useEffect(() => {
    if (props.isLeadQualifier && !props.textTypeOption) {
      setCurrentTextTypeOption(AI_PROMPT_TEXT_TYPES.ASK);
      setProp((props: any) => (props.textTypeOption = AI_PROMPT_TEXT_TYPES.ASK));
    }
  }, [props.isLeadQualifier]);

  useEffect(() => {
    if (existingGeneratedData?.getAiTextResponseForNode?.length) {
      const { getAiTextResponseForNode } = existingGeneratedData;
      setAllGeneratedTexts(getAiTextResponseForNode?.map((text: string) => text) || []);
    }
  }, [existingGeneratedData?.getAiTextResponseForNode?.length]);

  const scrollToSuggestions = () => {
    const sidebarContainerElement = document.querySelector('.builder__settings-sidebar__container');
    const targetElement = (document.querySelector('#ai-suggestions-title') as HTMLElement)
      ?.offsetParent as HTMLElement;

    if (targetElement && sidebarContainerElement)
      sidebarContainerElement.scrollTop = targetElement.offsetTop;
  };

  useEffect(() => {
    scrollToSuggestions();
  }, [allGeneratedTexts.length]);

  const isAdBuilderHeadlineElement =
    isAdBuilderPath() && (elementType as string) === textTypesEnum.HEADLINE;

  useEffect(() => {
    if (!isAdBuilderPath()) return;
    const limits = elementTypeTextLimits[elementType as string];
    if (limits) {
      setTextMinLimit(limits.min);
      setTextMaxLimit(limits.max);
    }
  }, [elementType]);

  const handleSetPromptText = (value: string) => {
    setPromptText(value);
    if (shouldGenerateMore) setShouldGenerateMore(false);
  };

  const type =
    elementType === elementTypeObj[mediaTypeEnum.HAUPTINHALT]
      ? 'MediaText'
      : elementType === elementTypeObj[mediaTypeEnum.CALL_TO_ACTION]
      ? 'BannerText'
      : elementTypeObj[elementType as mediaTypeEnum];

  const handleTextGeneration = async (mode: 'new' | 'more' = 'new') => {
    const isGenerateMore = mode === 'more';
    const allSiblings = getSiblingNodeNames(query, parentId, nodeId);
    const allSiblingNames = allSiblings?.map((sibling: any) => sibling.displayName);
    const commonElements = LeadsQualifiers.filter(element => allSiblingNames.includes(element));
    const questionType = commonElements?.length ? commonElements?.[0] : nodeName;
    mixpanel.track(mixpanelEvents.TEXT_GENERATION_BUTTON);
    try {
      const aiTextScopeCondition = shouldUseSelectedText ? 'SelectedText' : 'WholeText';
      getAiTextResponse({
        variables: {
          input: {
            nodeId,
            ...(shouldUseSelectedText ? { selectedText: html } : {}),
            maxLength: shouldUseSelectedText ? selectedTextLength + 10 : textMaxLimit,
            minLength: shouldUseSelectedText ? selectedTextLength - 10 : textMinLimit,
            promptMessage: promptText || '',
            type: elementType
              ? type
              : currentTextTypeOption === AI_PROMPT_TEXT_TYPES.FREE
              ? freeText
              : currentTextTypeOption,
            questionType,
            ...(!isAdBuilderPath() && (props.textTypeOption === currentTextTypeOption || isGenerateMore) &&
              currentConversationId &&
              aiTextElementScope === aiTextScopeCondition && {
                conversationId: currentConversationId
              }),
            ...(isAdBuilderPath() && { adNodeId: getElementAppenderNodeId() }),
            completeNodeContext: processAsPlainText ? tiptapEditorState?.getText() : tiptapEditorState?.getHTML() as string
          }
        }
      });
      setAiTextElementScope(aiTextScopeCondition);
      setProp((props: any) => (props.textTypeOption = currentTextTypeOption));
      setShouldGenerateMore(true);
    } catch (error) {
      message.error(AiTextMessages.generateFailed);
    }
  };

  const handleReplace = (newText: string) => {
    mixpanel.track(mixpanelEvents.CLICK_ON_GENERATED_TEXT_CARD);
    let updatedText = newText;
    if (shouldReplaceTextColor) {
      updatedText = replaceHtmlTextColor(newText, changeColor ? '#fff' : '', setCenter);
    }
    if (processAsPlainText && !updatedText.startsWith('<p')) {
      updatedText = newText.split(/\r?\n/).filter((text: string) => text.trim()).map((text: string) => `<p>${text.trim()}</p>`).join('');
    }
    replaceTextInEditorState(
      tiptapEditorState as Editor,
      updatedText,
      isUsingSelectedText,
      !!changeColor
    );

    if (shouldUseSelectedText) setPreviousSelection(html);
    setSelectedTemplateText(newText);
  };

  const handleTextTypeChange = (option: AI_PROMPT_TEXT_TYPES) => {
    setCurrentTextTypeOption(option);
    setShouldGenerateMore(false);
    const selected = textTypeDefaultLength.find(el => el.type === option);
    setTextMaxLimit(selected?.max as number);
    setTextMinLimit(selected?.min as number);
  };

  const handleGoToAiSettings = () => {
    mixpanel.track(mixpanelEvents.GO_TO_AI_SETTING);
    dispatch(
      setGeneralSettingsTabView(
        emptyGeneralSettings?.[0] || GeneralSettingsTabView.COMPANY_INFORMATION
      )
    );
    navigate('/builder/grundeinstellungen');
  };

  const htmlState = getSelectedHTMLInTiptapEditorState(tiptapEditorState as Editor)

  const html = htmlState;

  const conditionButton: any = {
    isAdGeneratedInputMissing: {
      text:  "Zu den KI-Einstellungen",
      onClick: () => {
        setTemplatesModalVisible(true);
      }
    }
  };

  const conditions = [
    {
      condition: !isAdBuilderPath() || hasAdGenerationInput ? false : true,
      messageKey: 'isAdGeneratedInputMissing',
      heading: headings[SETTINGS_OVERLAY_MESSAGE_KEY.IS_AD_GENERATED_INPUT_MISSING],
      icon: `${process.env.PUBLIC_URL}/block-overlay/ai-chip.png`
    },
    {
      condition: isOutOfAiTokens,
      messageKey: 'isOutOfAiTokens',
      heading: headings[SETTINGS_OVERLAY_MESSAGE_KEY.IS_OUT_OF_AI_TOKENS],
      icon: aiTokenRebuyCheck
    }
  ];

  const finalTextTypeOptions = props.isLeadQualifier
    ? textTypeOptions.map(el =>
        el.value != AI_PROMPT_TEXT_TYPES.ASK ? { ...el, disabled: true } : { ...el }
      )
    : textTypeOptions.filter(tt => tt.value !== AI_PROMPT_TEXT_TYPES.ASK);

  return (
    <div className="ai-text-settings mb-5">
      <BlockSettingsOverlay conditions={conditions} conditionButton={conditionButton} />
      {isAdBuilderPath() && (
        <Templates
          visible={templateModalVisible}
          toggleVisible={() => setTemplatesModalVisible(!templateModalVisible)}
          adGenerationData={adGenerationInput as AdGeneration}
          hideDefaultTemplateTab={true}
          shouldGenerateAd={false}
        ></Templates>
      )}

      <Row className="builder__settings-sidebar__row">
        {showOptionPicker && (
          <Col span={24} className="mt-1 mb-3 px-4">
            <BuilderSettingsTitle title="Art" />
            <OptionsPicker
              options={finalTextTypeOptions}
              handleChange={value => {
                mixpanel.track(mixpanelEvents.TEXT_TYPE);
                handleTextTypeChange(value);
              }}
              value={currentTextTypeOption}
            />
          </Col>
        )}

        {shouldUseSelectedText && (
          <Col span={24} className="mb-3 px-4">
            <div className="marked-text" dangerouslySetInnerHTML={{ __html: cleanHtml(html) }} />
            <label className="small-text mr-2">
              <SelectOutlined /> Markierter Text
            </label>
          </Col>
        )}

        {(currentTextTypeOption || !showOptionPicker) && (
          <Col span={24} className="mb-3">
            <BuilderSettingsTogglePro
              infoPopoverText={AiTextMessages.markTextToUseThis}
              disabled={!selectedText}
              title="Markierten Text verbessern"
              rightContent={
                <Switch
                  size="small"
                  checked={shouldUseSelectedText}
                  onChange={checked => setIsUsingSelectedText(checked)}
                />
              }
              classNames={!shouldUseSelectedText && !showOptionPicker ? 'border-top-0' : ''}
            />
          </Col>
        )}

        {currentTextTypeOption === AI_PROMPT_TEXT_TYPES.FREE && (
          <Col span={24} className="mb-3 px-4">
            <BuilderSettingsTitle title="Verwendungszweck (ca. 10 Wörter)" classNames="mt-1" />
            <TextArea
              value={freeText}
              onChange={e => setFreeText(e.target.value)}
              placeholder="z.B. vertrauenschaffender Text über dem Formular für die Dateneingabe-Seite"
              rows={3}
              maxLength={100}
            />
          </Col>
        )}

        {(currentTextTypeOption || !showOptionPicker) && (
          <>
            <Col span={24} className="mb-3 px-4">
              <BuilderSettingsTitle title="Anweisung (ca. 5 Wörter)" classNames="mt-1" />
              <TextArea
                value={promptText}
                onChange={e => handleSetPromptText(e.target.value)}
                placeholder={commandPlaceholderByTextType[currentTextTypeOption]}
                rows={3}
                maxLength={1000}
              />
            </Col>
            <Col span={24} className="mb-3 px-4">
              <Button
                onClick={() => {
                  if (isOutOfAiTokens) {
                    setIsRebuyModalOpened(true);
                  } else {
                    shouldGenerateMore ? handleTextGeneration('more') : handleTextGeneration();
                  }
                }}
                type="primary"
                loading={textGenLoading}
                style={{ width: '100%' }}
                disabled={
                  !(isUsingSelectedText || promptText?.length > 3) || hasMinMaxValidationError
                }
                icon={shouldGenerateMore ? <RetweetOutlined /> : <EditOutlined />}
              >
                {shouldGenerateMore && !textGenLoading ? 'Weitere Vorschläge' : 'Text generieren'}
              </Button>
            </Col>

            {!shouldUseSelectedText && (
              <Col span={24} className="mb-3 px-4">
                <MinMaxNumberInput
                  maxLimit={textMaxLimit}
                  minLimit={textMinLimit}
                  setMaxLimit={setTextMaxLimit}
                  setMinLimit={setTextMinLimit}
                  setHasMinMaxValidationError={setHasMinMaxValidationError}
                  maximum={10000}
                  title="Wortanzahl"
                  disabled={isAdBuilderHeadlineElement}
                  condition={isAdBuilderPath() && textMaxLimit > elementTypeTextMaxLimit + 10}
                  message={AiTextMessages.limitExceeded(elementTypeTextMaxLimit)}
                />
              </Col>
            )}
            <Col span={24} className="mb-3 px-4">
              {allGeneratedTexts?.length > 0 && (
                <>
                  <Title id="ai-suggestions-title" className="settings-title" level={4}>
                    Vorschläge
                  </Title>
                  <div>
                    {existingGeneratedLoading ? (
                      <div className="loader-container">
                        <DefaultSmallLoader loading={existingGeneratedLoading} />
                      </div>
                    ) : (
                      allGeneratedTexts
                        .slice(-3)
                        .reverse()
                        .map((text: any, idx: number) => {
                          const isLastTwo = lastGeneratedRef.current.includes(text);
                          const shortedTextLength = 250;
                          const displayText = `${cleanHtml(
                            text?.message?.slice(0, shortedTextLength)
                          )}${text?.message?.length > shortedTextLength ? '...' : ''}`;
                          const plainTextFromHtml = text?.message.replace(/<[^>]*>/g, ' ');

                          return (
                            <Row key={idx} className="py-2">
                              <Col span={24}>
                                <Card
                                  hoverable
                                  onClick={() => handleReplace(text?.message)}
                                  className={`generated-text-wrapper cursor-pointer ${
                                    isLastTwo && textGotGenerated ? 'focus-transition' : ''
                                  } p-1`}
                                >
                                  {selectedTemplateText === text.message && (
                                    <div className="template-block__inner__active-overlay">
                                      <CheckCircleOutlined />
                                    </div>
                                  )}
                                  <ConditionalTooltip
                                    disable={text?.message?.length <= shortedTextLength}
                                    title={plainTextFromHtml}
                                    placement="right"
                                  >
                                    <div dangerouslySetInnerHTML={{ __html: displayText }}></div>
                                  </ConditionalTooltip>
                                </Card>
                              </Col>
                            </Row>
                          );
                        })
                    )}
                  </div>
                </>
              )}
            </Col>
          </>
        )}

        {(type || currentTextTypeOption) && maxChatAiTokens > 0 && (
          <Col span={24} className="mb-3 px-4">
            <Title className="settings-title" level={4}>
              {AiTextMessages.quota}
              <Popover
                className="default-popover"
                content={
                  <div className="default-popover__content" style={{ maxWidth: 400 }}>
                    {AiTextMessages.quotaInfo}
                  </div>
                }
                trigger="hover"
              >
                <InfoCircleOutlined />
              </Popover>
            </Title>
            <Progress
              percent={currentChatAiUsagePercentage}
              format={() => currentChatAiUsageProgress}
              strokeColor={currentChatAiUsagePercentage > 90 ? 'red' : undefined}
            />
          </Col>
        )}

        {isAdBuilderPath() && (
          <Col span={24} className="mb-2 px-4">
            <Alert
              message={
                <div className="d-flex justify-content-center" style={{ gap: '7px' }}>
                  <FaVideo color="#1890ff" fontSize={16} style={{ marginTop: '3px' }} />
                  <div>
                    Lerne in 2 Minuten, wie du die Text-Kl optimal nutzt.{' '}
                    <div className="link-styles" style={{ color: '#40A9FF' }}>
                      <a
                        style={{ color: '#40A9FF' }}
                        target="_blank"
                        href="https://hilfe.meetovo.de/de/article/text-ki-best-practices-1la83i0/?bust=1725535618853"
                      >
                        Jetzt ansehen!
                      </a>
                    </div>
                  </div>
                </div>
              }
            />
          </Col>
        )}

        <Col span={24} className="mb-2 px-4">
          <Alert
            message={
              type || currentTextTypeOption ? (
                <>
                  Ändern allgemeine Zielgruppen, KI und Job-Information in den{' '}
                  <div
                    className="link-styles"
                    style={{ color: '#40A9FF' }}
                    onClick={handleGoToAiSettings}
                  >
                    Grundeinstellungen
                  </div>
                </>
              ) : (
                <>
                  Best Practices: So nutzt du die Text Kl, damit du die besten Ergebnisse erhältst:{' '}
                  <div className="link-styles">
                    <a
                      target="_blank"
                      href="https://hilfe.meetovo.de/de/article/text-ki-best-practices-1la83i0/?bust=1725535618853"
                    >
                      Video ansehen (Dauer: 2 Min.)
                    </a>
                  </div>
                </>
              )
            }
            type="info"
            showIcon
          />
        </Col>

        {isAdBuilderPath() && !isAiGeneratedAd && (
          <Col span={24} className="mb-2 px-4">
            <Alert
              message={
                <>
                  Ändern Sie die Anzeigeninformationen im {''}
                  <div
                    className="link-styles"
                    style={{ color: '#40A9FF' }}
                    onClick={async () => {
                      const response = await getAdGenerationInput(getElementAppenderNodeId());
                      const data = response.data.getAdGenerationInput;
                      setAdGenerationInput({
                        ...data,
                        [`promptMessage${data.type}`]: data.promptMessage
                      });
                      setTemplatesModalVisible(true);
                    }}
                  >
                    Anzeigeninformationen
                  </div>
                </>
              }
              type="info"
              showIcon
            />
          </Col>
        )}
      </Row>
      <TokenRebuyModal visible={isRebuyModalOpened} onClose={() => setIsRebuyModalOpened(false)} />
    </div>
  );
};

export default AiTextSettings;
