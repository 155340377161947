import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DraggableLocation } from 'react-beautiful-dnd';
import {
  BookingInterface,
  StatusInterface,
  BookingTag,
  EventStatus,
  BookingActionEventInterface
} from '../container/Events';
import { EventsMessages } from '../../config/messages';
import { reorder, reorderQuoteMap, getKanbanState } from '../utils/kanbanHelpers';
import { message } from 'antd';
import { csvMaker } from '../helper/csvMaker';
import moment from 'moment';
import download from 'downloadjs';
import {
  getAllBookingsThunk,
  deleteStatusColumn,
  manualBooking,
  fetchSingleBooking,
  changeBookingStatusAndOrder,
  addNewColumn,
  updateStatusColumn,
  handleDownload,
  deleteEventThunk,
  deleteBulkEventThunk,
  handleSetSelectedEvent,
  createBookingTagsThunk,
  handleUpdateManualLeadQualifier,
  onEventDragEnd,
  changeBookingStatusThunk,
  createOrUpdateRating,
  deleteBookingRating,
  changeBulkBookingStatusThunk,
  getLeadQualifier,
  updateBookingTags,
  fetchAllBookings
} from './thunk';
import { RootState } from '../../redux/store';
import { useAppSelector } from '../../redux/hooks';
import { EventState, EventDrawerTabView, SelectedEvent } from '../components/EventKanban/types';

// Initial State
const initialState: EventState = {
  availableStatus: [],
  leadQualifier: {},
  bookings: [],
  eventCache: {},
  allBookingTags: [],
  allBookingTagsHashMap: {},
  selectedEvent: undefined,
  loading: false,
  loaders: {
    eventsLoading: false,
    bookingLoading: false,
    selectedEventLoading: false,
    deleteEventLoading: false,
    downloadLoading: false,
    loadingDeleteBookingStatus: false,
    loadingUpdateBookingStatus: false,
    loadingCreateBookingStatus: false,
    manualBookingLoading: false,
    statusChangeLoading: false,
    deleteRatingLoading: false,
    createOrUpdateRatingLoading: false,
    leadQualifierLoading: false,
    updateBookingTagLoading: false,
    paginatedBookingsLoading: false,
    updateLeadQualifierLoading: false
  },
  isBookingTagsChanged: false,
  error: null,
  eventDrawerTabView: EventDrawerTabView.CONTACT,
  updateStatusPayload: null,
  selectedBookings: [],
  eventsMoved: {
    phase: '',
    count: 0
  },
  eventsMovedToDecline: []
};

export const bookingSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    setEventDrawerTabView: (state, action: PayloadAction<EventDrawerTabView>) => {
      state.eventDrawerTabView = action.payload;
    },
    updateSelectedEvent: (state, action: PayloadAction<SelectedEvent | undefined>) => {
      state.selectedEvent = action.payload;
    },
    setAvailableStatus: (state, action: PayloadAction<StatusInterface[]>) => {
      state.availableStatus = action.payload;
    },
    setSelectedEvent: (state, action: PayloadAction<SelectedEvent | undefined>) => {
      state.selectedEvent = action.payload;
    },
    setEventCache: (state, action: PayloadAction<any>) => {
      state.eventCache = action.payload;
    },
    setDeclineApplicationId: (state, action: PayloadAction<any>) => {
      if (action.payload === undefined) {
        state.eventsMovedToDecline = [];
      } else if (
        typeof action.payload === 'number' &&
        !state.eventsMovedToDecline.includes(action.payload)
      ) {
        state.eventsMovedToDecline = [action.payload];
      }
    },
    clearEventCache: state => {
      state.eventCache = {};
    },
    setIsBookingTagsChanged: (state, action: PayloadAction<any>) => {
      state.isBookingTagsChanged = action.payload;
    },
    handleBookingChange: (state, action: PayloadAction<{ field: string; value: any }>) => {
      if (state.selectedEvent) {
        const { field, value } = action.payload;

        state.selectedEvent = {
          ...state.selectedEvent,
          event: {
            ...state.selectedEvent.event,
            [field]: value
          }
        };

        if (state.bookings) {
          const updatedBookings = state.bookings.map((single: BookingInterface) => {
            if (single.id === state.selectedEvent?.event.id) {
              // @ts-ignore
              single[field] = value;
            }
            return single;
          });

          state.bookings = updatedBookings;
        }

        const eventId = state.selectedEvent.event.id;
        if (state.eventCache && state.eventCache[eventId]) {
          state.eventCache[eventId] = {
            ...state.eventCache[eventId],
            [field]: value
          };
        }
      }
    },
    resetEventsMoved: (state, action: any) => {
      state.eventsMoved = {
        phase: '',
        count: 0
      };
    },
    resertEventsMovedToDecline: (state, action: any) => {
      state.eventsMovedToDecline = [];
    },
    setSelectedIds: (
      state,
      action: PayloadAction<{ record: BookingInterface; check: boolean }>
    ) => {
      if (action.payload.check) {
        state.selectedBookings.push(action.payload.record);
      } else {
        state.selectedBookings = state.selectedBookings.filter(
          record => record.id !== action.payload.record.id
        );
      }
    },
    setAllIdsSelection: (
      state,
      action: PayloadAction<{ records: BookingInterface[]; check: boolean }>
    ) => {
      if (action.payload.check) {
        state.selectedBookings = [];
      } else {
        state.selectedBookings = action.payload.records;
      }
    },
    handleLocalDelete: (state, action: PayloadAction<number>) => {
      const updatedBooking = state.bookings.filter((single: any) => single.id !== action.payload);
      state.bookings = updatedBooking;
    },
    setAllBookingTags: (state, action: PayloadAction<BookingTag[]>) => {
      state.allBookingTags = action.payload;
      if (state.allBookingTags?.length) {
        const transformed = state.allBookingTags?.reduce((acc: any, item: any) => {
          const { id, ...rest } = item;
          acc[id] = rest;
          return acc;
        }, {});

        state.allBookingTagsHashMap = transformed;
      }
    },
    setBookings: (state, action: PayloadAction<BookingInterface[]>) => {
      state.bookings = action.payload;
    },
    changeBookingStatusLocally: (
      state,
      action: PayloadAction<{ id: number; eventStatus: EventStatus; sortOrder: number }>
    ) => {
      const newBooking = state.bookings.map((single: any) => {
        if (single.id === action.payload.id) {
          single.bookingStatus = {
            status: action.payload.eventStatus?.status,
            value: action.payload.eventStatus?.value,
            color: action.payload.eventStatus?.color || 'default'
          };
          single.sortOrder = action.payload.sortOrder;
        }
        return single;
      });

      state.bookings = newBooking;
    },
    handleAddCSVBookingEvent: (state, action: PayloadAction<BookingInterface[]>) => {
      state.bookings = [...state.bookings, ...action.payload];
    },
    handleUpdateBookingEvents: (
      state,
      action: PayloadAction<{ eventId: number; bookingEvents: BookingActionEventInterface }>
    ) => {
      const updatedApplicationState = state.bookings.map((booking: BookingInterface) => {
        if (booking.id === action.payload.eventId) {
          booking.bookingActionEvent = action.payload.bookingEvents;
        }
        return booking;
      });
      state.bookings = updatedApplicationState;
    },
    setBookingMetadata: (state, action) => {
      state.availableStatus = action.payload.getAllBookingStatus;
      state.allBookingTags = action.payload.getAllBookingTags;
      state.leadQualifier = action.payload.getLeadQualifier;
    },
    setPaginatedBookings: (state, action: PayloadAction<BookingInterface[]>) => {
      state.bookings = [...state.bookings, ...action.payload];
    },
    updatePaginatedBookingsLoading: (state, action: PayloadAction<boolean>) => {
      state.loaders.paginatedBookingsLoading = action.payload;
    }
  },
  extraReducers: {
    // @ts-ignore
    [getAllBookingsThunk.pending]: (state: ApplicationsState, action: any) => {
      if (!state.loaders.paginatedBookingsLoading) {
        state.loaders.bookingLoading = true;
        state.bookings = [];
      }
    },
    // @ts-ignore
    [getAllBookingsThunk.fulfilled]: (state: ApplicationsState, action: any) => {
      if (!action.payload) {
        state.loaders.bookingLoading = false;
        return;
      }
      if (action.payload.getAllBookingStatus) {
        state.availableStatus = action.payload.getAllBookingStatus;
      }
      if (action.payload.getAllBookingTags) {
        state.allBookingTags = action.payload.getAllBookingTags;
        if (state.allBookingTags?.length) {
          state.allBookingTagsHashMap = state.allBookingTags.reduce((acc: any, item: any) => {
            const { id, ...rest } = item;
            acc[id] = rest;
            return acc;
          }, {});
        }
      }
      if (action.payload.getLeadQualifier) {
        state.leadQualifier = action.payload.getLeadQualifier;
      }
      if (action.payload.getTrackings) {
        state.trackings = action.payload.getTrackings;
      }

      state.eventCache = {};
      state.loaders.bookingLoading = false;
    },
    // @ts-ignore
    [fetchAllBookings.rejected]: (state: ApplicationsState, action: any) => {
      state.loaders.paginatedBookingsLoading = false;
    },
    // @ts-ignore
    [getAllBookingsThunk.rejected]: (state: ApplicationsState, action: any) => {
      state.error = action.payload as string;
      state.loaders.bookingLoading = false;
    },
    // @ts-ignore
    [changeBookingStatusAndOrder.pending]: (state: ApplicationsState, action: any) => {
      state.loading = true;
    },
    // @ts-ignore
    [changeBookingStatusAndOrder.fulfilled]: (state: ApplicationsState, action: any) => {
      const response = action.payload;
      if (state.selectedEvent) {
        state.selectedEvent = {
          ...state.selectedEvent,
          event: {
            ...state.selectedEvent?.event,
            bookingStatus: response.bookingStatus
          }
        };
      }
    },
    // @ts-ignore
    [changeBookingStatusAndOrder.rejected]: (state: ApplicationsState, action: any) => {
      state.error = action.payload as string;
      state.loading = false;
    },
    // @ts-ignore
    [manualBooking.pending]: (state: ApplicationsState, action: any) => {
      state.loaders.manualBookingLoading = true;
    },
    // @ts-ignore
    [manualBooking.fulfilled]: (state: ApplicationsState, action: any) => {
      const { manualBooking, shouldCreate } = action.payload;
      if (shouldCreate) {
        state.bookings = [...state.bookings, manualBooking];
        state.eventDrawerTabView = EventDrawerTabView.INPUTS;
        state.selectedEvent = { index: 0, event: manualBooking };
      }
      state.eventCache = {
        ...state.eventCache,
        [action.payload.manualBooking.id]: manualBooking
      };
      state.loaders.manualBookingLoading = false;
    },
    // @ts-ignore
    [manualBooking.rejected]: (state: ApplicationsState, action: any) => {
      state.error = action.payload as string;
      state.loaders.manualBookingLoading = false;
    },
    // @ts-ignore
    [fetchSingleBooking.fulfilled]: (state: ApplicationsState, action: any) => {
      const eventData = action.payload;
      const updatedApplicationState = state.bookings.map((booking: BookingInterface) => {
        if (booking.id === action.payload.id) {
          booking = eventData;
        }
        return booking;
      });

      state.bookings = updatedApplicationState;
      state.eventCache = { ...state.eventCache, [eventData.id as number]: eventData };
      state.selectedEvent = { event: eventData, index: 0 };
      state.loading = false;
    },
    // @ts-ignore
    [fetchSingleBooking.rejected]: async (state: ApplicationsState, action: any) => {
      state.error = action.error.message || 'Failed to fetch booking details';
      state.loading = false;
    },
    // @ts-ignore
    [addNewColumn.pending]: (state: ApplicationsState, action: any) => {
      state.loaders.loadingCreateBookingStatus = true;
    },
    // @ts-ignore
    [addNewColumn.fulfilled]: (state: ApplicationsState, action: any) => {
      const newStatus = action.payload;
      state.availableStatus.push(newStatus);
      state.loaders.loadingCreateBookingStatus = false;
    },
    // @ts-ignore
    [addNewColumn.rejected]: (state: ApplicationsState, action: any) => {
      state.loaders.loadingCreateBookingStatus = false;
    },
    // @ts-ignore
    [updateStatusColumn.pending]: (state: ApplicationsState, action: any) => {
      state.loaders.loadingUpdateBookingStatus = true;
    },
    // @ts-ignore
    [updateStatusColumn.fulfilled]: (state: ApplicationsState, action: any) => {
      const updatedStatus = action.payload;
      state.availableStatus = state.availableStatus.map((status: any) => {
        return status.id === updatedStatus.id ? updatedStatus : status;
      });
      state.loaders.loadingUpdateBookingStatus = false;
    },
    // @ts-ignore
    [updateStatusColumn.rejected]: (state: ApplicationsState, action: any) => {
      state.loaders.loadingUpdateBookingStatus = false;
    },
    // @ts-ignore
    [deleteStatusColumn.pending]: (state: ApplicationsState, action: any) => {
      state.loaders.loadingDeleteBookingStatus = true;
    },
    // @ts-ignore
    [deleteStatusColumn.fulfilled]: (state: ApplicationsState, action: any) => {
      const status = action.payload;
      state.availableStatus = state.availableStatus.filter(
        (availstatus: any) => availstatus.id !== status.id
      );
      state.loaders.loadingDeleteBookingStatus = false;
    },
    // @ts-ignore
    [deleteStatusColumn.rejected]: (state: ApplicationsState, action: any) => {
      state.loaders.loadingDeleteBookingStatus = false;
    },
    // @ts-ignore
    [createBookingTagsThunk.fulfilled]: (state: ApplicationsState, action: any) => {
      if (!state.isBookingTagsChanged) return;
      const { selectedTagsWithIds, responseWithTagIds } = action.payload;
      const allBookingTagsWithIds = state.allBookingTags.filter((tag: any) => tag.id);
      const allTags = [...allBookingTagsWithIds, ...responseWithTagIds];

      const updatedEvent = {
        ...state.selectedEvent,
        event: {
          ...state.selectedEvent?.event,
          tags: [...selectedTagsWithIds, ...responseWithTagIds]
        }
      };

      state.allBookingTags = allTags;
      if (state.allBookingTags?.length) {
        const transformed = state.allBookingTags?.reduce((acc: any, item: any) => {
          const { id, ...rest } = item;
          acc[id] = rest;
          return acc;
        }, {});

        state.allBookingTagsHashMap = transformed;
      }

      state.isBookingTagsChanged = false;
      if (state.selectedEvent?.event?.id) {
        state.eventCache[state.selectedEvent.event.id] = updatedEvent.event;
      }
      state.selectedEvent = updatedEvent;

      if (state.bookings) {
        const updatedBookings = state.bookings.map((single: BookingInterface) => {
          if (single.id === state.selectedEvent?.event.id) {
            // @ts-ignore
            single.tags = [...selectedTagsWithIds, ...responseWithTagIds];
          }
          return single;
        });

        state.bookings = updatedBookings;
      }
    },
    // @ts-ignore
    [onEventDragEnd.pending]: (state: ApplicationsState, action: any) => {
      const { result, shouldShowEmailPopup } = action.meta.arg;

      try {
        if (result.combine) {
          return;
        }

        if (!result.destination) {
          return;
        }

        const { columns, ordered } = getKanbanState(state.bookings, state.availableStatus);
        const source: DraggableLocation = result.source;
        // @ts-ignore
        const destination: DraggableLocation = result.destination;

        if (source.droppableId === destination.droppableId && source.index === destination.index) {
          return;
        }

        // Handle column reordering
        if (result.type === 'COLUMN') {
          const reOrdered: string[] = reorder(ordered, source.index, destination.index);

          const payload = reOrdered.map((item, index) => ({
            id: state.availableStatus.find((status: any) => status.value === item)?.id,
            sortOrder: index
          }));

          const updatedStatuses = state.availableStatus.map((single: any) => {
            const updatedSortOrder = payload.find(
              (sortOrderItem: any) => sortOrderItem.id === single.id
            );
            if (updatedSortOrder) {
              single.sortOrder = updatedSortOrder.sortOrder;
            }

            return single;
          });

          state.availableStatus = updatedStatuses;
          state.updateStatusPayload = { input: payload };
        } else {
          const bookingStatus = state.availableStatus.find(
            (status: any) => status.value === destination.droppableId
          );
          // // @ts-ignore
          const dragEndSource = columns[source.droppableId][source.index];

          const data = reorderQuoteMap({
            quoteMap: columns,
            source,
            destination
          });

          const updatedColumns = {
            ...data.quoteMap,
            [destination.droppableId]: data.quoteMap[destination.droppableId].map(
              (item: any, idx: number) => {
                if (idx === destination.index) {
                  return {
                    ...item,
                    bookingStatus: bookingStatus
                  };
                }
                return item;
              }
            )
          };

          const dragSource = updatedColumns[source.droppableId].map((value: any, i: number) => ({
            id: value.id,
            sortOrder: i
          }));

          const dragDestination = updatedColumns[destination.droppableId].map(
            (value: any, i: number) => ({
              id: value.id,
              sortOrder: i
            })
          );

          let onDragCombine: any;
          if (source.droppableId === destination.droppableId) {
            onDragCombine = dragSource;
          } else {
            onDragCombine = dragSource.concat(dragDestination);
          }

          if (!bookingStatus) {
            console.error('Booking status is undefined');
            return;
          }

          const eventStatus: EventStatus = {
            color: bookingStatus.color,
            status: bookingStatus.status,
            value: bookingStatus.value
          };

          const updateBookings = state.bookings.map((single: any) => {
            if (single.id === dragEndSource.id) {
              single.bookingStatus = {
                status: eventStatus?.status,
                value: eventStatus?.value,
                color: eventStatus?.color || 'default'
              };
              single.sortOrder = destination.index;
              delete state.eventCache[dragEndSource.id];
            }

            const updatedSortOrder = onDragCombine.find(
              (sortOrderItem: any) => sortOrderItem.id === single.id
            );
            if (updatedSortOrder) {
              single.sortOrder = updatedSortOrder.sortOrder;
            }

            return single;
          });

          state.updateStatusPayload = {
            status: { value: destination.droppableId, id: parseInt(result.draggableId) },
            newSortOrder: onDragCombine
          };
          state.bookings = updateBookings;
          if (
            destination.droppableId === 'DECLINE' &&
            dragEndSource?.version !== 'V1' &&
            shouldShowEmailPopup
          ) {
            if (!state.eventsMovedToDecline.includes(dragEndSource.id)) {
              state.eventsMovedToDecline.push(dragEndSource.id);
            }
          }
          if (!!state.eventsMoved.phase && state.eventsMoved.phase === eventStatus.status) {
            state.eventsMoved.count += 1;
          } else {
            state.eventsMoved.phase = eventStatus.status;
            state.eventsMoved.count = 1;
          }
        }
      } catch (error) {
        console.error('Error in onEventDragEnd:', error);
        message.error('Something went wrong!');
      }
    },
    // @ts-ignore
    [onEventDragEnd.fulfilled]: (state: ApplicationsState, action: any) => {
      state.updateStatusPayload = null;
    },
    // @ts-ignore
    [changeBulkBookingStatusThunk.pending]: (
      // @ts-ignore
      state: ApplicationsState,
      action: PayloadAction<{}>
    ) => {
      state.loaders.statusChangeLoading = true;
    },
    // @ts-ignore
    [changeBulkBookingStatusThunk.rejected]: (
      // @ts-ignore
      state: ApplicationsState,
      action: PayloadAction<{}>
    ) => {
      state.loaders.statusChangeLoading = false;
    },
    // @ts-ignore
    [changeBulkBookingStatusThunk.fulfilled]: (
      // @ts-ignore
      state: ApplicationsState,
      action: PayloadAction<{
        ids: number[];
        eventStatus: {
          status: string;
          value: string;
          color: string;
        };
      }>
    ) => {
      const eventsMoved: number[] = [];
      const newBookings = state.bookings.map((single: any) => {
        if (action.payload.ids.includes(single.id)) {
          if (
            action.payload.eventStatus.value === 'DECLINE' &&
            single.bookingStatus.value !== 'DECLINE'
          ) {
            eventsMoved.push(single.id);
          }
          single.bookingStatus = {
            status: action.payload.eventStatus.status,
            value: action.payload.eventStatus.value,
            color: action.payload.eventStatus.color
          };
        }
        return single;
      });
      if (action.payload.eventStatus.value === 'DECLINE') {
        state.eventsMovedToDecline = eventsMoved;
      }
      state.bookings = newBookings;
      state.loaders.statusChangeLoading = false;
    },
    // @ts-ignore
    [changeBookingStatusThunk.pending]: (state: ApplicationState, action: any) => {
      state.loaders.statusChangeLoading = true;
    },
    // @ts-ignore
    [changeBookingStatusThunk.fulfilled]: (state: ApplicationState, action: any) => {
      const newBooking = state.bookings.map((single: any) => {
        if (single.id === action.payload.id) {
          const newStatus = {
            status: action.payload.eventStatus?.status,
            value: action.payload.eventStatus?.value,
            color: action.payload.eventStatus?.color || 'default'
          };

          single.bookingStatus = newStatus;

          const eventId = single.id;
          if (state.eventCache && state.eventCache[eventId]) {
            state.eventCache[eventId] = {
              ...state.eventCache[eventId],
              bookingStatus: newStatus
            };
          }
        }
        return single;
      });

      state.bookings = newBooking;
      if (!!state.eventsMoved.phase && state.eventsMoved.phase === action.payload.status) {
        state.eventsMoved.count += 1;
      } else {
        state.eventsMoved.phase = action.payload.status;
        state.eventsMoved.count = 1;
      }
      state.loaders.statusChangeLoading = false;
    },
    // @ts-ignore
    [changeBookingStatusThunk.rejected]: (state: ApplicationState, action: any) => {
      state.loaders.statusChangeLoading = false;
    },
    // @ts-ignore
    [handleDownload.pending]: (state: ApplicationsState, action: any) => {
      state.loaders.downloadLoading = true;
    },
    // @ts-ignore
    [handleDownload.fulfilled]: (state: ApplicationsState, action: any) => {
      const { data, funnels } = action.payload;
      const { getBookings, getLeadQualifier } = data;
      if (getBookings.length === 0) return message.info(EventsMessages.noDataToExport);

      const csvData = csvMaker({
        bookings: getBookings,
        leadQualifiers: getLeadQualifier,
        funnels
      });

      const file = new Blob([csvData], { type: 'text/csv' });

      download(file, `Export_${moment().format('DD.MM.YYYY_HH.mm')}.csv`);
      state.loaders.downloadLoading = false;
    },
    // @ts-ignore
    [handleDownload.rejected]: (state: ApplicationsState, action: any) => {
      state.loaders.downloadLoading = false;
    },
    // @ts-ignore
    [deleteBulkEventThunk.pending]: (state: ApplicationsState) => {
      state.loaders.deleteEventLoading = true;
    },
    // @ts-ignore
    [deleteBulkEventThunk.fulfilled]: (state: ApplicationsState) => {
      state.loaders.deleteEventLoading = false;
      state.bookings = state.bookings.filter(
        (single: BookingInterface) =>
          !state.selectedBookings.find((sb: BookingInterface) => sb.id === single.id)
      );
      state.selectedBookings = [];
    },
    // @ts-ignore
    [deleteBulkEventThunk.rejected]: (state: ApplicationsState) => {
      state.loaders.deleteEventLoading = false;
    },
    // @ts-ignore
    [deleteEventThunk.pending]: (state: ApplicationsState) => {
      state.loaders.deleteEventLoading = true;
    },
    // @ts-ignore
    [deleteEventThunk.fulfilled]: (state: ApplicationsState, action: any) => {
      state.loaders.deleteEventLoading = false;
      state.bookings = state.bookings.filter(
        (booking: BookingInterface) => booking.id !== action.payload
      );
    },
    // @ts-ignore
    [deleteEventThunk.rejected]: (state: ApplicationsState) => {
      state.loaders.deleteEventLoading = false;
    },
    // @ts-ignore
    [getLeadQualifier.pending]: (state: ApplicationsState) => {
      state.loaders.leadQualifierLoading = true;
    },
    // @ts-ignore
    [getLeadQualifier.fulfilled]: (state: ApplicationsState) => {
      state.loaders.leadQualifierLoading = false;
    },
    // @ts-ignore
    [getLeadQualifier.rejected]: (state: ApplicationsState) => {
      state.loaders.leadQualifierLoading = false;
    },
    // @ts-ignore
    [handleSetSelectedEvent.pending]: (state: ApplicationsState) => {
      state.loaders.selectedEventLoading = true;
    },
    // @ts-ignore
    [handleSetSelectedEvent.fulfilled]: (state: ApplicationsState, action: PayloadAction<any>) => {
      const { selectedEvent, eventCache } = action.payload || {};
      if (selectedEvent !== undefined) {
        state.selectedEvent = selectedEvent;
      }
      if (eventCache) {
        state.eventCache = { ...state.eventCache, ...eventCache };
      }
      state.loaders.selectedEventLoading = false;
    },
    // @ts-ignore
    [handleSetSelectedEvent.rejected]: (state: ApplicationsState) => {
      state.loaders.selectedEventLoading = false;
    },
    // @ts-ignore
    [handleUpdateManualLeadQualifier.pending]: (
      // @ts-ignore
      state: ApplicationsState,
      action: PayloadAction<any>
    ) => {
      state.loaders.updateLeadQualifierLoading = true;
    },
    // @ts-ignore
    [handleUpdateManualLeadQualifier.rejected]: (
      // @ts-ignore
      state: ApplicationsState,
      action: PayloadAction<any>
    ) => {
      state.loaders.updateLeadQualifierLoading = false;
    },
    // @ts-ignore
    [handleUpdateManualLeadQualifier.fulfilled]: (
      // @ts-ignore
      state: ApplicationsState,
      action: PayloadAction<any>
    ) => {
      state.loaders.updateLeadQualifierLoading = false;
      const { updatedEvent, eventId } = action.payload;

      if (state.selectedEvent?.event?.id === eventId) {
        state.selectedEvent = { ...state.selectedEvent, event: updatedEvent };
      }

      state.eventCache[eventId] = updatedEvent;
    },
    // @ts-ignore
    [deleteBookingRating.pending]: (state: ApplicationsState) => {
      state.loaders.deleteRatingLoading = true;
    },
    // @ts-ignore
    [deleteBookingRating.fulfilled]: (state: ApplicationsState) => {
      state.loaders.deleteRatingLoading = false;
    },
    // @ts-ignore
    [deleteBookingRating.rejected]: (state: ApplicationsState) => {
      state.loaders.deleteRatingLoading = false;
    },
    // @ts-ignore
    [createOrUpdateRating.pending]: (state: ApplicationsState) => {
      state.loaders.createOrUpdateRatingLoading = true;
    },
    // @ts-ignore
    [createOrUpdateRating.fulfilled]: (state: ApplicationsState) => {
      state.loaders.createOrUpdateRatingLoading = false;
    },
    // @ts-ignore
    [createOrUpdateRating.rejected]: (state: ApplicationsState) => {
      state.loaders.createOrUpdateRatingLoading = false;
    },
    // @ts-ignore
    [updateBookingTags.pending]: (state: ApplicationsState) => {
      state.loaders.updateBookingTagLoading = true;
    },
    // @ts-ignore
    [updateBookingTags.fulfilled]: (state: ApplicationsState) => {
      state.loaders.updateBookingTagLoading = false;
    },
    // @ts-ignore
    [updateBookingTags.rejected]: (state: ApplicationsState) => {
      state.loaders.updateBookingTagLoading = false;
    }
  }
});

export const useBookingState = (): EventState => {
  return useAppSelector((state: RootState) => state.bookings);
};

export const {
  setEventDrawerTabView,
  setSelectedEvent,
  updateSelectedEvent,
  setAvailableStatus,
  handleBookingChange,
  setAllBookingTags,
  setBookings,
  setEventCache,
  changeBookingStatusLocally,
  handleLocalDelete,
  handleAddCSVBookingEvent,
  setIsBookingTagsChanged,
  handleUpdateBookingEvents,
  clearEventCache,
  setDeclineApplicationId,
  setSelectedIds,
  resetEventsMoved,
  resertEventsMovedToDecline,
  setAllIdsSelection,
  setBookingMetadata,
  setPaginatedBookings,
  updatePaginatedBookingsLoading
} = bookingSlice.actions;

export default bookingSlice.reducer;
