import { Row, Switch, Col } from 'antd';
import React from 'react';
import BuilderSettingsTitle from './BuilderSettingsTitle';

type BuilderSettingWithSwitchProps = {
  title: string;
  icon?: React.ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  description?: string;
  shouldShowDescriptionWhenDisabled?: boolean;
  previewImageWhenDisabled?: string;
};

const BuilderSettingsTitleWithSwitch = ({
  title,
  checked,
  onChange,
  icon,
  description,
  shouldShowDescriptionWhenDisabled,
  previewImageWhenDisabled
}: BuilderSettingWithSwitchProps) => {

  const shouldShowDescription = shouldShowDescriptionWhenDisabled ? description && !checked : description;
  const showPreviewImage = !checked && previewImageWhenDisabled;

  return (
    <>
      <Row>
        <Col span={22}>
          <BuilderSettingsTitle title={title} icon={icon} />
        </Col>
        <Col span={2}>
          <Switch size="small" checked={checked} onChange={onChange} />
        </Col>
      </Row>
      {shouldShowDescription && <Row>
        <p className='mb-0'>
          {description}
        </p>
      </Row>}
      {showPreviewImage && (
        <div className="mt-2 mb-4">
          <img 
            src={previewImageWhenDisabled} 
            alt="Preview" 
            className="decent-border w-100" 
          />
        </div>
      )}
    </>
  );
};

export default BuilderSettingsTitleWithSwitch;
