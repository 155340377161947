import { Select } from 'antd';
import React from 'react';
import { getTextFromHtml } from '../../Builder/helper/sharedFunctions';
import { StatusInterface } from '../../Events/container/Events';

interface Props {
  value?: number | string;
  defaultValue?: number | string;
  defaultValueRender?: React.ReactNode;
  onChange?: any;
  availableStatus: StatusInterface[];
  loading?: boolean;
  useIdAsValue?: boolean;
}

const StatusSelectList = ({
  value,
  defaultValue,
  defaultValueRender,
  onChange = () => {},
  availableStatus,
  loading,
  useIdAsValue = true
}: Props) => {
  return (
    <Select
      style={{ width: '100%' }}
      data-testid="booking-status-selection"
      value={value}
      defaultValue={defaultValue}
      loading={loading}
      onChange={onChange}
      {...(process.env.NODE_ENV === 'test' && { open: true })}
    >
      {defaultValueRender && defaultValue && (
        <Select.Option key="default" value={defaultValue}>
          {defaultValueRender}
        </Select.Option>
      )}
      {availableStatus?.map(single => (
        <Select.Option
          key={single.id}
          value={useIdAsValue ? single.id : single.value}
          data={single}
        >
          <div
            className="d-flex align-items-center"
            style={{ columnGap: 8 }}
            data-testid={single.status}
          >
            <div
              style={{
                height: 12,
                width: 12,
                borderRadius: '50%',
                backgroundColor: single.color
              }}
            ></div>
            <span>{getTextFromHtml(single.status)}</span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default StatusSelectList;
